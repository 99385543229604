import {css} from '@emotion/core';
import {DatePicker, Modal} from 'antd';
import {PickerProps, SinglePickerProps} from 'antd/lib/date-picker/interface';
import moment from 'moment';
import {FC, memo, useState} from 'react';
import {useAsync} from 'react-async-hook';
import {TransferMethod, useQuote, useShippingDate} from '../../contexts/Quote';
import {useSession} from '../../contexts/Session';

const isoDay = 'YYYY-MM-DD';
interface DeliveryDateProps {
  delay?: number;
}

const datepickerCss = css`
  margin-left: 20px;
  input {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #78787d;
    font-weight: bold;
    color: #78787d;

    &:focus {
      box-shadow: none;
    }
  }
`;

const DeliveryDate: FC<DeliveryDateProps> = ({delay = 0}) => {
  const session = useSession();
  const [{lines, transfer}] = useQuote();
  const [modalVisiblity, setModalVisibility] = useState(false);
  const [shippingDate, setShippingDate] = useShippingDate();
  const shippingDateMoment = moment(shippingDate);

  const hasEmpreintePhysique = lines.find((line) =>
    line.articles?.find((article) => article.empreintePhysique),
  );
  const transferViaOctoCloud = transfer.method === TransferMethod.OCTOCLOUD;
  const transferPhysical = transfer.method === TransferMethod.PHSYCIAL;

  const joursFermesAsync = useAsync(
    async () =>
      (await session.fetch<string[]>('Utils', 'getJoursFermes', {})) || [],
    [],
  );

  const delayedDate = moment().startOf('day');
  {
    let delayIterator = delay;
    while (delayIterator >= 0) {
      delayedDate.add(1, 'days');

      if (
        ![6, 7].includes(Number(delayedDate?.format('E') || '-1')) &&
        !(joursFermesAsync.result || []).includes(
          delayedDate?.format(isoDay) || 'invalid date',
        )
      ) {
        --delayIterator;
      }
    }
  }

  const isDateDisabled: PickerProps['disabledDate'] = (date) => {
    if (date?.isBefore(delayedDate)) {
      return true;
    }

    if ([6, 7].includes(Number(date?.format('E') || '-1'))) {
      return true;
    }

    if (
      (joursFermesAsync.result || []).includes(
        date?.format(isoDay) || 'invalid date',
      )
    ) {
      return true;
    }

    return false;
  };

  if (shippingDate && isDateDisabled(shippingDateMoment)) {
    setShippingDate(undefined);
  }

  const handleChange: SinglePickerProps['onChange'] = (date, dateString) => {
    if ((date?.format(isoDay) || 'invalid date') !== moment().format(isoDay)) {
      setModalVisibility(true);
    }

    setShippingDate(dateString);
  };

  return (
    <div style={{marginTop: 10}}>
      <span
        css={[
          {color: '#78787d'},
          hasEmpreintePhysique || transferViaOctoCloud || transferPhysical
            ? {display: 'none'}
            : {},
        ]}
      >
        Date de livraison souhaitée <span css={{color: '#CD1619'}}>*</span>
      </span>
      {hasEmpreintePhysique || transferViaOctoCloud || transferPhysical ? (
        <p css={{color: '#CD1619'}}>
          La date de livraison vous sera confirmée à réception des empreintes
        </p>
      ) : (
        <DatePicker
          css={datepickerCss}
          onChange={handleChange}
          value={shippingDateMoment.isValid() ? shippingDateMoment : null}
          disabledDate={isDateDisabled}
        />
      )}

      <Modal
        visible={modalVisiblity}
        title="Confirmation"
        cancelText="OK"
        onCancel={() => setModalVisibility(false)}
        okButtonProps={{style: {display: 'none'}}}
      >
        <p>Votre commande sera livrée le {shippingDateMoment.format('LL')}</p>
      </Modal>
    </div>
  );
};

export default memo(DeliveryDate);
