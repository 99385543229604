import {css} from '@emotion/core';
import {Button, Col, Dropdown, Icon, Layout, Menu, Row} from 'antd';
import {FC, memo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Banner from './components/Banner';
import {useSession} from './contexts/Session';

const {Header: AntHeader} = Layout;

const headerCss = css`
  backgroundcolor: white;
  borderbottom: solid #28314e 1px;
  padding: 25px 60px;
  height: 100%;

  @media (max-width: 992px) {
    padding: 5px 10px !important;
    height: auto !important;
  }

  .logo-button {
    text-align: center;
    display: inline-block;
    position: relative;
    top: -15px;
    img {
      max-height: 40px;
    }
    div {
      color: #28314e;
      text-transform: uppercase;
      font-size: 10px;
    }
  }

  .pitch {
    position: relative;
    top: 4px;
    font-size: 15px;
    line-height: 20px;
    color: #28314e;
    margin-left: 20px;

    @media (max-width: 992px) {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .header-social-container {
    text-align: right;
    position: relative;
    line-height: 37px;

    @media (max-width: 992px) {
      padding-right: 10px !important;
    }

    > * {
      margin: 0 3px;
    }

    .ant-btn.facebook {
      color: white;
      background-color: #3b5a9a;
      border-color: #3b5a9a;
    }

    .ant-btn.youtube {
      color: white;
      background-color: #c3191e;
      border-color: #c3191e;
    }

    .ant-btn.linkedin {
      color: white;
      background-color: #0073b2;
      border-color: #0073b2;
    }

    .ant-btn.instagram {
      color: white;
      background-color: #833ab4;
      border-color: #833ab4;
    }
  }

  .user-col {
    line-height: 15px;
    margin-top: 5px;
    text-align: right;
    padding-right: 52px !important;

    .anticon {
      font-size: 30px;
    }

    @media (max-width: 992px) {
      line-height: 70px;
      .anticon {
        font-size: 22px;
      }
    }
  }
`;

const menuCss = css`
  .ant-dropdown-menu-item-disabled {
    background-color: #ececf1;
    color: black;
  }

  img.logo-menu {
    max-width: 15px;
    float: left;
    position: relative;
    top: 5px;
  }

  .anticon,
  img.logo-menu {
    margin-right: 5px;
  }
`;

const Header: FC = () => {
  const {user} = useSession();
  const location = useLocation();

  const name = user?.contact ?? user?.userName ?? user?.fullName ?? '';

  const menu = (
    <Menu css={menuCss}>
      <Menu.Item disabled={true} style={{textAlign: 'center'}}>
        {name}
      </Menu.Item>

      <Menu.Item>
        <a
          href="//espace-pro.interson-protac.com/orders"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link">Suivi de commande</Button>
        </a>

        {/* <Link to="/orders">
          <Button type="link">Suivi de commande</Button>
        </Link> */}
      </Menu.Item>
      <Menu.Item>
        <a
          href="//espace-pro.interson-protac.com/request/order"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link">Bon de commande</Button>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="//espace-pro.interson-protac.com/request/delivery"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link">Bon de livraison</Button>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="//espace-pro.interson-protac.com/request/invoice"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link">Facture</Button>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href="//espace-pro.interson-protac.com/settings"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link">Paramètres</Button>
        </a>
      </Menu.Item>

      <Menu.Item>
        <Link to="/logout">
          <img
            src="/2022/deconnexion.svg"
            alt="Logo déconnexion"
            className="logo-menu"
          />
          <div>Déconnexion</div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader
      css={headerCss}
      style={{
        backgroundColor: 'white',
        padding: '25px 60px',
        height: '100%',
      }}
    >
      <Row gutter={15}>
        <Col span={3} style={{maxWidth: '256px'}}>
          <Link to="/">
            <img
              src="/EAROW.png"
              alt="Logo EAROW By Prodways"
              style={{
                maxWidth: '100%',
                position: 'relative',
                top: '-14px',
              }}
            />
          </Link>
        </Col>
        <Col span={14}>
          <p className="pitch">
            <strong style={{textTransform: 'uppercase'}}>
              Commande, gestion et suivi de commande...
            </strong>
            <br />
            La seule plateforme dédiée aux audioprothésistes où tout est à
            portée de clic !
          </p>
        </Col>

        <Col span={4} className="header-social-container">
          <a
            href="//www.facebook.com/intersonbyprodways/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button className="facebook" shape="circle" icon="facebook" />
          </a>
          <a
            href="//www.instagram.com/intersonbyprodways/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button className="instagram" shape="circle" icon="instagram" />
          </a>
          <a
            href="//www.youtube.com/channel/UC4IJAMz5Rb3CSHuNq8vR5MQ"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button className="youtube" shape="circle" icon="youtube" />
          </a>
          <a
            href="//www.linkedin.com/company/interson-by-prodways/?viewAsMember=true"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button className="linkedin" shape="circle" icon="linkedin" />
          </a>
        </Col>

        {user ? (
          <Col span={3} className="user-col">
            <Dropdown overlay={menu} placement="bottomCenter">
              <Icon type="user" style={{color: '#28314e'}} />
            </Dropdown>
          </Col>
        ) : null}

        <Col span={24}>
          <Banner />
        </Col>
      </Row>
    </AntHeader>
  );
};

export default memo(Header);
