import {css} from '@emotion/core';
import {Button, Col, Modal, Result, Row} from 'antd';
import {FC, Fragment, ReactNode, memo, useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {getBaseQuoteOptions, useLines, useQuote} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {User, UserType} from '../../util/fetch-wakanda';
import {cssUtils} from '../../util/render';
import {Order} from '../Orders/data';
import Pitch from '../Pitch';
import Buttons from './Buttons';
import Cart from './Cart';
import ClientOrderData from './ClientOrderData';
import Comment from './Comment';
import DeliveryDate from './DeliveryDate';
import DocumentModal from './DocumentModal';
import Patient from './Patient';
import Prints from './Prints';
import Shipment from './Shipment';
import StickerModal from './StickerModal';
import TechnicalModal from './TechnicalModal';
import Terms from './Terms';
import {FinalizeError, finalize, getArticles, isFinalizeError} from './util';

const summaryCss = css`
  max-width: 900px;
  margin: auto;
  padding: 0 5px;

  .banner {
    max-width: 1200px;

    .background img {
      max-width: 100%;
    }

    .foreground {
      color: white;
      text-transform: uppercase;

      position: absolute;
      top: 5px;
      left: 0;

      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;

      font-size: 17px;

      img {
        height: 40px;
        margin-right: 10px;
      }

      p {
        margin: 0;
        line-height: 44px;
      }
    }
  }
`;

const Summary: FC = () => {
  const [quote, setQuote] = useQuote();
  const [lines, setLines] = useLines();
  const user = useUser();
  const session = useSession();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalSuccessful, setModalSuccessful] = useState(true);
  const [modalError, setModalError] = useState<FinalizeError | null>(null);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState<Order>();

  const fabricationDelay = lines
    // only the first line?
    .slice(0, 1)
    .reduce(
      (sum, line) =>
        sum +
        (line?.articles || []).reduce(
          (articlesSum, {delaiFabAM, delaiFabPM, selected}) =>
            articlesSum +
            ((new Date().getHours() < 12 ? delaiFabAM : delaiFabPM) || 0) *
              Math.min(1, (selected || []).filter((ear) => ear).length),
          0,
        ),
      0,
    );

  const transporterDelay = quote.transporter?.delai || 0;
  const delay = Math.max(1, transporterDelay + fabricationDelay - 2);

  const prepareLines = async (usr: User): Promise<void> => {
    const _lines = await Promise.all(
      lines.map(async (line) => getArticles(quote, line, session, usr)),
    );

    // @ts-ignore
    setLines([_lines.filter((line) => !!line).reverse()[0]]);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await prepareLines(user);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, quote.transfer.method]);

  const onFinalize = async (): Promise<void> => {
    if (!user) {
      return;
    }

    setLoading(true);
    const result = await finalize(session, quote, lines, user);

    if (!isFinalizeError(result)) {
      setOrder(result);
      setModalSuccessful(true);
      setModalVisible(true);
      setModalError(null);
    } else {
      setModalSuccessful(false);
      setModalVisible(true);
      setModalError(result);
    }

    setLoading(false);
  };

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const emptyQuote = (): void => {
    setQuote(getBaseQuoteOptions());
  };

  const orderButtons: ReactNode[] = [];
  if (order) {
    if (user.type !== UserType.CLIENT) {
      orderButtons.push(<StickerModal key={0} order={order} large={true} />);
      orderButtons.push(<TechnicalModal key={1} order={order} large={true} />);
    } else {
      orderButtons.push(<DocumentModal key={0} order={order} large={true} />);
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" css={[cssUtils.heightP(), summaryCss]}>
        <Col span={24}>
          <Pitch
            background="Embouts"
            foreground="Récapitulatif de la commande"
          />
        </Col>

        <Col span={24}>
          <Patient patientOnly={true} /> {/* user.type === UserType.CLIENT */}
          <Cart css={{margin: '10px 0'}} />
          <Row css={{margin: '10px 0'}}>
            <Col span={24}>
              <ClientOrderData />
            </Col>
            <Col span={24}>
              <Shipment>
                <DeliveryDate delay={delay} />
              </Shipment>
            </Col>
            {user.type === UserType.CLIENT ? (
              <Col span={24}>
                <Prints />
              </Col>
            ) : null}
          </Row>
          <Comment />
          {user?.type === UserType.CLIENT ? <Terms /> : null}
          <Buttons loading={loading} onFinalize={onFinalize} />
        </Col>
      </Row>
      <Modal
        title={
          modalSuccessful
            ? 'Commande réussie'
            : 'Mise en ligne de la commande a échoué'
        }
        visible={modalVisible}
        closable={false}
        footer={null}
        width={720}
      >
        <Result
          status={modalSuccessful ? 'success' : 'error'}
          title={
            modalSuccessful ? (
              <span
                style={{
                  color: '#CD1619',
                  textTransform: 'uppercase',
                  letterSpacing: '0.3rem',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                Commande validée !
              </span>
            ) : (
              'Erreur lors de la mise en ligne de votre commande'
            )
          }
          subTitle={
            modalSuccessful ? (
              <div css={{maxWidth: 400, margin: 'auto', fontWeight: 'bold'}}>
                <p>
                  Merci d&apos;avoir passé votre commande.
                  <br />
                  Vous recevrez un e-mail de confirmation dès que la commande
                  sera prise en compte par nos services.
                </p>
              </div>
            ) : (
              <div css={{maxWidth: 500, margin: 'auto'}}>
                {modalError?.error === 'attachments' ? (
                  <p>
                    La mise en ligne de vos emprientes numérique a échoué. Merci
                    de réessayer plus tard. Si cette erreur persiste, veuillez
                    contacter le support.
                  </p>
                ) : (
                  <p>
                    Une erreur inconnue est survenue. Merci de réessayer plus
                    tard. Si cette erreur persiste, veuillez contacter le
                    support.
                  </p>
                )}
              </div>
            )
          }
          extra={
            modalSuccessful ? (
              <Fragment>
                {orderButtons}
                <Link to="/quote">
                  <Button
                    type="primary"
                    onClick={emptyQuote}
                    style={{marginLeft: 8}}
                    icon="plus"
                  >
                    <span style={{textTransform: 'uppercase'}}>
                      Nouvelle commande
                    </span>
                  </Button>
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  type="primary"
                  onClick={() => setModalVisible(false)}
                  style={{marginLeft: 8}}
                >
                  OK
                </Button>
              </Fragment>
            )
          }
        />
      </Modal>
    </Fragment>
  );
};

export default memo(Summary);
