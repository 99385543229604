import {css} from '@emotion/core';
import {Icon} from 'antd';
import {IconProps} from 'antd/lib/icon';
import moment, {Moment} from 'moment';
import {
  ComponentProps,
  ComponentType,
  Dispatch,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  memo,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  RefAttributes,
  RefForwardingComponent,
  SetStateAction,
} from 'react';
import {EVENT, TYPE} from '../components/Options/data';
import {
  Group,
  SELECTED_EARS,
  SELECTED_EARS_TEXT,
} from '../components/Options/static';
import {Option, TBoolean, Tuple} from '../contexts/Quote';

export const colors = {
  primary: '#CD1619',
  secondary: '#6096B3',
};

export const cssUtils = {
  height: (em: number) =>
    css`
      height: ${em}em;
    `,
  heightP: (percent = 100) =>
    css`
      height: ${percent}%;
    `,
  vHidden: css`
    visibility: hidden;
  `,
};

export const cssDisabledLine = css`
  background-color: lightgray;
  color: grey;
  cursor: not-allowed;
`;

export const cssRowWithoutOuterGutter = css`
  > .ant-col:first-of-type {
    padding-left: 0 !important;
  }
  > .ant-col:last-of-type {
    padding-right: 0 !important;
  }
`;

export const renderIconInputPrefix = (
  icon: string,
  props: IconProps = {},
): ReactElement => <Icon type={icon} style={{color: '#bfbfbf'}} {...props} />;

export const cssCursorPointer = css`
  cursor: pointer;
`;

export const cssOption = css`
  padding: 5px 10px;
  text-align: right;
  border-bottom: 2px solid #78787d;
  border-right: 2px solid #78787d;
  @media (min-width: 992px) {
    padding: 10px 30px;
    font-size: 17px;
  }

  text-align: center;
`;

export const borderContainerCss = css`
  .ant-row:not(:last-of-type) .ant-col,
  .ant-row-flex:not(:last-of-type) .ant-col {
  }

  .ant-row .ant-col:last-of-type,
  .ant-row-flex .ant-col:last-of-type {
    border-right-width: 0;
    text-align: center;
  }
`;

const {format: _currencyFormat} = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const {format: _dateFormat} = new Intl.DateTimeFormat('fr-FR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const currencyFormat = (nb: number, plain = false): string => {
  if (plain) {
    return _currencyFormat(nb);
  }

  if (nb >= 1000000000) {
    return `MM${_currencyFormat(nb / 1000000000)}`;
  }
  if (nb >= 1000000) {
    return `M${_currencyFormat(nb / 1000000)}`;
  }
  if (nb >= 1000) {
    return `k${_currencyFormat(nb / 1000)}`;
  }

  return _currencyFormat(nb);
};

export const Currency: FC<{price: number}> = ({price}) => (
  <span>{price ? currencyFormat(price, true) : '-- €'}</span>
);

export const Date: FC<{timestamp: number}> = ({timestamp}) => (
  <span>{_dateFormat(timestamp)}</span>
);

export const BuhBye: FC = () => (
  <div css={{fontSize: 20}}>
    <span role="img" aria-labelledby="bye">
      👋
    </span>
  </div>
);

export const cssRightEar = css`
  display: inline-block;
  transform: rotateY(180deg);
`;

export const SELECTED_EARS_EMOJIS: {[selected in SELECTED_EARS]: ReactNode} = {
  [SELECTED_EARS.NONE]: null,
  [SELECTED_EARS.LEFT_ONLY]: (
    <span
      role="img"
      aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
    >
      <img
        css={[{height: '40px'}]}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
      />
    </span>
  ),
  [SELECTED_EARS.RIGHT_ONLY]: (
    <span
      role="img"
      css={cssRightEar}
      aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
    >
      <img
        css={[{height: '40px', transform: 'rotateY(180deg)'}]}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY]}
      />
    </span>
  ),
  [SELECTED_EARS.BOTH]: (
    <span role="img" aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.BOTH]}>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <img
        css={[{height: '40px'}]}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
      />
      &nbsp;
      <img
        css={[{height: '40px', transform: 'rotateY(180deg)'}]}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY]}
      />
    </span>
  ),
};

export const SELECTED_EARS_SHORT_TEXT: {
  [selected in SELECTED_EARS]: ReactNode;
} = {
  [SELECTED_EARS.NONE]: null,
  [SELECTED_EARS.LEFT_ONLY]: (
    <span
      role="img"
      css={{fontWeight: 'bold'}}
      aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
    >
      <span css={{color: '#0C3D59'}}>OG</span>
    </span>
  ),
  [SELECTED_EARS.RIGHT_ONLY]: (
    <span
      role="img"
      css={{fontWeight: 'bold'}}
      aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
    >
      <span css={{color: '#CD1619'}}>OD</span>
    </span>
  ),
  [SELECTED_EARS.BOTH]: (
    <span
      role="img"
      css={{fontWeight: 'bold'}}
      aria-labelledby={SELECTED_EARS_TEXT[SELECTED_EARS.BOTH]}
    >
      <span css={{color: '#0C3D59'}}>OG</span> /{' '}
      <span css={{color: '#CD1619'}}>OD</span>
    </span>
  ),
};

export const DisabledEar: FC = () => (
  <span role="img" aria-labelledby="Oreille désactivée" />
);

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typedMemo: <T extends ComponentType<any>>(
  Component: T,
  propsAreEqual?: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>,
  ) => boolean,
) => T = memo;
export const typedForwardRef: <T, P = {}>(
  Component: RefForwardingComponent<T, P>,
) => ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
> = forwardRef;

export const fixTimestampMilliseconds = (timestamp: number): number =>
  timestamp < 9999999999 ? timestamp * 1000 : timestamp;

export const getMomentFromTimestamp = (timestamp: number): Moment =>
  moment.utc(fixTimestampMilliseconds(timestamp));

export function renderTimestamp(timestamp: number): ReactNode {
  return timestamp ? (
    <Date timestamp={fixTimestampMilliseconds(timestamp)} />
  ) : null;
}

export const getSelectedGroups = (
  selectedOptions: Option[],
  wasVisited = false,
): {[key: string]: TBoolean} =>
  selectedOptions.reduce((_res, {type, selected, visited}) => {
    if (wasVisited && !visited) return _res;

    if (!_res[type]) {
      _res[type] = [false, false];
    }

    selected.map(
      (_selected, index) =>
        (_res[type][index] = _res[type][index] || _selected),
    );

    return _res;
  }, {} as {[key: string]: TBoolean});

const getRequiredGroups = (groups: Group[]): Group[] =>
  groups.filter(({required, disabled}) => required && !disabled);

export const getEmptyRequiredGroups = (
  selectedOptions: Option[],
  groups: Group[],
  ears: TBoolean,
): Group[] => {
  const selectedGroups = getSelectedGroups(selectedOptions);
  return getRequiredGroups(groups).filter(({name}) => {
    if (name === TYPE.EVENT_TYPE_AND_SIZE) {
      const eventOptions = selectedOptions.filter(
        ({type}) => type === TYPE.EVENT_TYPE_AND_SIZE,
      );

      return [0, 1]
        .map((index) => {
          if (!ears[index]) {
            return false;
          }

          const earEventOptions = eventOptions.filter(
            ({selected}) => selected[index],
          );

          return !(
            earEventOptions.find(({name}) => name === EVENT.NONE) ||
            earEventOptions.length >= 2
          );
        })
        .find((invalid) => invalid);
    }

    return !selectedGroups[name];
  });
};

export const getMissingEarsRequiredGroups = ({
  ears,
  groups,
  selectedOptions,
}: {
  ears: TBoolean;
  groups: Group[];
  selectedOptions: Option[];
}): Tuple<Group[]> => {
  const requiredGroups = getRequiredGroups(groups);
  const selectedGroups = getSelectedGroups(selectedOptions);
  const emptyRequiredGroups = getEmptyRequiredGroups(
    selectedOptions,
    groups,
    ears,
  );

  return ears.map((enabled, ear) =>
    enabled
      ? requiredGroups.filter(
          (group) =>
            (!selectedGroups[group.name] || !selectedGroups[group.name][ear]) &&
            !emptyRequiredGroups.includes(group),
        )
      : [],
  ) as Tuple<Group[]>;
};

export const getMissingGroups = (
  ears: Tuple<boolean>,
  selectedOptions: Option[],
  groups: Group[],
): {
  emptyRequiredGroups: Group[];
  missingEarsRequiredGroups: Tuple<Group[]>;
  missingGroups: Group[];
} => {
  const emptyRequiredGroups = getEmptyRequiredGroups(
    selectedOptions,
    groups,
    ears,
  );
  const missingEarsRequiredGroups = getMissingEarsRequiredGroups({
    ears,
    groups,
    selectedOptions,
  });

  const missingGroups = [
    ...emptyRequiredGroups,
    ...missingEarsRequiredGroups.flat(),
  ];
  return {emptyRequiredGroups, missingEarsRequiredGroups, missingGroups};
};

export type StateOf<T> = [T, Dispatch<SetStateAction<T>>];

export type Dispatcher<T> = (value: T) => void;
export type ValueDispatcher<T> = [T, Dispatcher<T>];
export type ValuePartialDispatcher<T> = [T, Dispatcher<Partial<T>>];
export type NullableValueDispatcher<T> = [T | null, Dispatcher<T | undefined>];
