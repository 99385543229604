import {css} from '@emotion/core';
import {Col, Input, Row} from 'antd';
import {FC, memo} from 'react';
import {useCustomMarkFields} from '../../contexts/Quote';

const CUSTOM_MARK_FIELD_MAX_LENGTH = 30;

const customMarkFieldsCss = css`
  margin: auto !important;
  max-width: 700px;

  label {
    float: left;
    color: #78787d;
    font-weight: bold;
  }
`;

interface CustomMarkFieldOption {
  type?: 'text' | 'number';
  label: string;
}

const CustomMarkFields: FC<{options: [CustomMarkFieldOption, CustomMarkFieldOption]}> = ({options}) => {
  const [customMarkFields, setCustomMarkFields] = useCustomMarkFields();

  return (
    <Row css={customMarkFieldsCss} gutter={20}>
      <Col span={12}>
        <label>{options[0].label} <span css={{color: '#CD1619'}}>*</span></label>
        <Input
          value={customMarkFields?.[0]}
          type={options[0].type || 'text'}
          maxLength={CUSTOM_MARK_FIELD_MAX_LENGTH}
          placeholder={options[0].label}
          onChange={(e) => {
            setCustomMarkFields([e.target.value.toString(), customMarkFields?.[1] || '']);
          }}
        />
      </Col>
      <Col span={12}>
        <label>{options[1].label} <span css={{color: '#CD1619'}}>*</span></label>
        <Input
          value={customMarkFields?.[1]}
          type={options[1].type || 'text'}
          maxLength={CUSTOM_MARK_FIELD_MAX_LENGTH}
          placeholder={options[1].label}
          onChange={(e) => {
            setCustomMarkFields([customMarkFields?.[0] || '', e.target.value.toString()]);
          }}
        />
      </Col>
    </Row>
  );
};

export default memo(CustomMarkFields);
