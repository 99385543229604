import {css} from '@emotion/core';
import {Col, Row, Tooltip} from 'antd';
import {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {TBoolean, useCurrentSelectedOptions} from '../../contexts/Quote';
import {
  borderContainerCss,
  cssOption,
  getEmptyRequiredGroups,
  getMissingEarsRequiredGroups,
  getSelectedGroups,
} from '../../util/render';
import {getSelectedEars, Group, SELECTED_EARS} from './static';

const cssLink = css`
  --background-color: transparent;
  --selected-background-color: transparent;
  --selected-color: #CD1619;
  --filled-color: #78787d;

  color: #78787d;
  :hover {
    color: #78787d;
  }

  .ant-col {
    background-color: var(--background-color);
    cursor: pointer;
  }

  &.selected .ant-col {
    cursor: default;
  }
  &.disabled .ant-col {
    cursor: not-allowed;
  }

  &.selected .ant-col {
    color: var(--selected-color);
    text-decoration: underline;
  }
  &.filled .ant-col {
    /* color: var(--filled-color); */
    /* text-decoration: underline; */
  }
  &.disabled .ant-col {
    filter: grayscale(100%);
  }
  &.required .ant-col::after {
    content: '*';
    color: #CD1619;
  }
`;

interface Props {
  groups: Group[];
  ears: TBoolean;
}

export const OptionsGroupPicker: FC<Props> = ({groups, ears}) => {
  const {pathname} = useLocation();
  const pathTokens = pathname.split('/');
  const [selectedOptions] = useCurrentSelectedOptions();

  const selectedEars = getSelectedEars(ears);

  const missingRequiredGroups = [
    ...getEmptyRequiredGroups(selectedOptions, groups, ears),
    ...getMissingEarsRequiredGroups({
      ears,
      groups,
      selectedOptions,
    }).flat(),
  ];
  const selectedGroups = Object.keys(getSelectedGroups(selectedOptions, true));

  return (
    <div css={[borderContainerCss]}>
      {groups.map(({name, label, disabled, required}, index) => (
        <Row key={index}>
          <Link
            to={name}
            css={cssLink}
            onClick={disabled ? (event) => event.preventDefault() : undefined}
            className={[
              'option-group-link',
              disabled ? 'disabled' : '',
              pathTokens[pathTokens.length - 1] === name ? 'selected' : '',
              required && !disabled ? 'required' : '',
              missingRequiredGroups.find(({name: _name}) => _name === name)
                ? 'required-missing'
                : '',
              selectedGroups.includes(name) ? 'filled' : '',
            ]
              .filter((className) => !!className)
              .join(' ')}
          >
            <Tooltip
              placement="right"
              title={
                disabled
                  ? selectedEars === SELECTED_EARS.NONE
                    ? 'Sélectionnez au moins une oreille'
                    : missingRequiredGroups.length
                    ? 'Sélectionnez une valeur pour les options obligatoires précédentes'
                    : 'Aucune option disponible'
                  : null
              }
            >
              <Col span={24} css={cssOption} style={{border: 'none'}}>
                {selectedGroups.includes(name) ? '✓ ' : ''}
                {label}
              </Col>
            </Tooltip>
          </Link>
        </Row>
      ))}
    </div>
  );
};
